<script setup>
import {ref} from "vue";
import Footer from "@/components/Footer.vue";
import { onMounted } from "vue";
import {getSecurityCodeAPI} from "@/api/api";
import axios from 'axios';

const description = ref('');

onMounted(() => {
    getSecurityCode();
});



async function getSecurityCode() {
    const res = await getSecurityCodeAPI();
    console.log(res.data.results[0]);
    description.value = res.data.results[0].security_code_desc;
}


</script>

<template>
    <div class="article-box">
      <div v-html="description"></div>
    </div>

</template>

<style scoped>
.article-box {
    width: 90%;
    margin: auto;
}

</style>