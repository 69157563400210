<template>
  <div>
    <div class="menu" style="display: flex">
      <div class="logo" style="cursor: pointer" @click="toHome">
        <img src="https://cdn.casetify.cn/img/ui/casetify-logo.png" alt="柯西" />
      </div>

      <a-menu mode="horizontal" :default-selected-keys="['0']" >
        <template v-for="(item,index) in menu" :key="index">
          <a-menu-item v-on:mouseover="changeNav(index)" @click="pageJump(item.jump_type, item.jump_data)"><span >{{ item.title }}</span></a-menu-item>
        </template>
      </a-menu>
    </div>
  </div>

  <div class="wrapper">
    <template v-for="(item, index) in menu">
      <div v-on:mouseleave="checkHide">
        <slot :name="'wrapper-' + item.id"></slot>
<!--        <div class="nav-dismiss" style="height: 10rem;width: 100%" v-if="action_nav === index"></div>-->
      </div>
    </template>
    <div class="nav-dismiss-full" v-show="action_nav !== -1"></div>
  </div>


</template>

<script setup>
import router from "@/router";
import { ref } from "vue";

const props = defineProps({
  menu:{
    type:Array
  },
})

const emit = defineEmits([
  'changeNav'
])

const action_nav = ref(-1)

const changeNav = function (index){
  if(action_nav.value === index){
    return
  }
  action_nav.value = index
  emit('changeNav',index)
}
const checkHide = function(){
  action_nav.value = -1
  emit('changeNav', -1)
}

function jumpSku(sku_id, spu_id=""){
  router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
}

function jumpSpu(spu_id){
  router.push({path:"/product/",query:{spu:spu_id}})
}

function jumpCollect(attributes){
  let query = {}
  query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
  query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
  query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
  query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
  query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
  query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
  query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
  query.special_name = !!attributes.special?Object.values(attributes.special).join(','):''
  query.influencer_name = !!attributes.influencer?Object.values(attributes.influencer).join(','):''
  router.push({path:"/collect/",query:query})
}

function toHome(){
  router.push({path:"/"})
}

function pageJump(jump_type, data){
  switch (jump_type) {
    case 'sku':
      jumpSku(data.sku_id, data.spu_id)
      break;
    case 'spu':
      jumpSpu(data.spu_id)
      break;
    case 'collect':
      jumpCollect(data)
      break;
    default:
      break;
  }
}

</script>

<style scoped>
  .menu{
    /* 一级菜单格高度  */
    --menu-item-height:7rem;
    /* 一级菜单padding参数 */
    --menu-item-padding: 0px 2rem;
    /* 一级菜单背景色 */
    --menu-item-background: #ffffff;
    /* 一级菜单文字颜色 */
    --menu-item-color: #111111;
    /* 一级菜单hover背景色 */
    --menu-item-hover-background: #ffffff;
    /* 一级菜单hover字体色 */
    --menu-item-hover-color:#111111;
    /* 一级菜单字体大小 */
    --menu-item-font-size:1.8rem;
    /* 一级菜单字体weight */
    --menu-item-font-weight:600;
  }

  .wrapper{
    position: absolute;
    top: var(--menu-item-height);
    width: 100%;
    left: 0;
    height: 100px;
    z-index: 101;
  }



  .menu {
    box-sizing: border-box;
    width: 100%;
  }

  .menu .logo{
    width:70rem;
    height: var(--menu-item-height);
    display: flex;
    align-items: center;
  }

  .menu .logo img{
    width:20rem;
    height: calc(var(--menu-item-height) * 0.5);
    object-fit: contain;
  }

  .menu>>>.arco-menu-item, .menu >>> .arco-menu-item.arco-menu-selected{
    line-height:var(--menu-item-height);
    font-size: var(--menu-item-font-size);
    font-weight: var(--menu-item-font-weight);
  }

  .menu>>>.arco-menu{
    background: var(--menu-item-background);
    height: var(--menu-item-height)
  }

  .menu>>>.arco-menu-overflow-wrap{
    height: 100%;
  }
  .menu>>>.arco-menu-inner{
    overflow: hidden;
    padding: 0;
  }
  .menu>>>.arco-menu-pop-header span:not(.arco-menu-icon-suffix){
    height: 100%;
    display: flex;
    align-items: center;
  }
  .menu>>>.arco-menu-pop-header:hover, .menu>>>.arco-menu-item:hover{
    color: var(--menu-item-hover-color);
    background-color: var(--menu-item-hover-background)
  }
  .menu>>>.arco-menu-item.arco-menu-selected, .menu>>>.arco-menu-pop-header.arco-menu-selected,.menu>>>.arco-menu-pop-header.arco-menu-selected:hover,.menu>>>.arco-menu-item.arco-menu-selected:hover{
    color: var(--menu-item-hover-color)!important;
    background-color: var(--menu-item-hover-background) !important;
  }

  .menu>>>.arco-menu-pop-header{
    height: 100%;
    font-size: var(--menu-item-font-size);
    background: var(--menu-item-background);
    color: var(--menu-item-color);
    padding: var(--menu-item-padding);
  }
  .menu>>>.arco-menu-item{
    background: var(--menu-item-background);
    color: var(--menu-item-color);
    padding: var(--menu-item-padding);
    text-align: center;
  }


  .menu>>>.arco-menu-icon-suffix{
    display: none;
  }

  .arco-trigger-menu-item:hover{
    color: #3aa0bd!important;
    font-weight: bolder;
    background-color: #FFF!important;
    transition: .5s;
  }

  .arco-trigger-menu-item:not(:last-child){
    border-bottom: solid 1px #e6e6e6;
  }

  .arco-trigger-menu-item{
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
  }

  .arco-trigger-menu-item.arco-trigger-menu-selected{
    font-weight: bolder;
    color: #3aa0bd!important;
    text-align: center;
  }

  .menu>>>.arco-menu-pop-trigger{
    width: 100%;
  }

  .nav-dismiss{
    background-color: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 101;
  }

  .nav-dismiss-full{
    background-color: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    right: 0;
    min-height: 100vh !important;
    height: 100%;
    width: 100%;
    z-index: 101;
  }

</style>
