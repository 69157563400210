<script setup>
import {ref} from "vue";
import { onMounted } from "vue";
import Footer from "@/components/Footer.vue";
import {getAboutUsAPI} from "@/api/api.js";

const description = ref('');


onMounted(() => {
    getAboutUs();
});



async function getAboutUs() {
    const res = await getAboutUsAPI();
    console.log(res.data.results[0]);
    description.value = res.data.results[0].about_us_desc;

    
}


</script>

<template>
    <div class="article-box">
      <div v-html="description"></div>
    </div>

</template>

<style scoped>
.article-box {
    width: 90%;
    margin: auto;
}


</style>