
<template>
  <div class="marquee">
    <div class="scroll-arrow scroll-left" @click="scrollLeft()">
      <icon-left />
    </div>
    <div class="scroll-marquee" ref="scrollMarquee">
      <template v-for="(item, index) in list">
        <a class="scroll-item" :style="[
            {
              flex:'1 0 calc(100% /5)',
              cursor:'pointer'
            }
        ]" @click="pageJump('marquee',item.name)">
          <img :src="item.img" alt=""/>
        </a>
      </template>
      <a class="scroll-item" :style="[
            {
              flex:'1 0 calc(100% /5)',
            }
        ]" v-if="list.length < 5" v-for="_ in 5 - list.length" />
    </div>
    <div class="scroll-arrow scroll-right" @click="scrollRight()">
      <icon-right />
    </div>
  </div>

</template>

<script>
import router from "@/router";
import {ref, toRefs} from "vue";
export default {
  name: 'Marquee',
  components:{

  },
  props:{
    list:{
      type:Array
    },
    collect_type:{
      type:String,
    }
  },
  methods:{
    left: function(){
      this.scrollLeft()
    },
    right:function (){
      this.scrollRight()
    }
  },
  setup(props){
    const scrollMarquee = ref();

    function scrollLeft(){
      scrollMarquee.value.scrollTo({
        left: scrollMarquee.value.scrollLeft - scrollMarquee.value.children[0].offsetWidth,
        behavior: 'smooth'
      })
    }

    function scrollRight(){
      scrollMarquee.value.scrollTo({
        left: scrollMarquee.value.scrollLeft + scrollMarquee.value.children[0].offsetWidth,
        behavior: 'smooth'
      })
    }

    const jumpSku = function (sku_id, spu_id=""){
      router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
    }

    const jumpSpu = function (spu_id){
      router.push({path:"/product/",query:{spu:spu_id}})
    }

    const jumpCollect = function (attributes){
      let query = {}
      query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
      query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
      query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
      query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
      query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
      query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
      query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
      query.special_name = !!attributes.special?Object.values(attributes.special).join(','):''
      query.influencer_name = !!attributes.influencer?Object.values(attributes.influencer).join(','):''
      router.push({path:"/collect/",query:query})
    }

    const pageJump = function (jump_type, data){
      console.log("pageJump ", jump_type, " ",data)
      switch (jump_type) {
        case 'sku':
          jumpSku(data.sku_id, data.spu_id)
          break;
        case 'spu':
          jumpSpu(data.spu_id)
          break;
        case 'collect':
          jumpCollect(data)
          break;
        case 'marquee':
          if(!data){
            return
          }
          const {collect_type} = toRefs(props)
          let a = {}
          a[collect_type.value]=[data]
            console.log(a)
          jumpCollect(a)
          break
        case 'carousel':
          if(!data){
            return
          }
          let d = {}
          d[data.key]=[data.value]
          jumpCollect(d)
          break
        default:
          break;
      }
    }

    return{
      scrollMarquee,
      scrollLeft,
      scrollRight,
      pageJump
    }
  }


}
</script>

<style scoped>

  .marquee{
    display: flex;
    align-items: center;
    position: relative;
  }

  .marquee:before{
    position: absolute;
    left: 4.9rem;
    top: 0;
    width: 0;
    height: 100%;
    border-left: 0.5rem solid #fff;
    content: '';
    display: block;
    z-index: 101;
  }

  .marquee:after{
    position: absolute;
    right: 4.9rem;
    top: 0;
    width: 0;
    height: 100%;
    border-right: 0.5rem solid #fff;
    content: '';
    display: block;
    z-index: 101;
  }

  .scroll-marquee{
    width: calc(100% - 2px);
    height: 20rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }

  .scroll-item{
    position: relative;
    background-position: center;
    background-size: cover;
    border-left: 0.1rem solid #f0f0f0;
    padding: 0 1.6rem;
    box-sizing: border-box;
  }

  .scroll-item:first-child{
    border-left: none;
  }

  .scroll-item img{
    width: 100%;
  }

  .scroll-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    background-color: rgba(217,217,217,.3);
    opacity: .3;
    color: #000;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.3;
    user-select: none;
    padding-bottom: 0.5rem;
    font-weight: 900;
  }


</style>
