
<template>
  <div class="marquee">
    <div class="scroll-arrow scroll-left" @click="scrollLeft()">
      <icon-left />
    </div>
    <div class="scroll-marquee" ref="scrollMarquee">
      <template v-for="(item, index) in list">
        <a class="scroll-item" :style="[
            {
              flex:'1 0 calc(100% /4)',
            }
        ]">
          <img :src="item.img" alt=""/>
          <div class="marquee-title">{{item.title}}</div>
          <div class="marquee-subtitle">{{item.subtitle}}</div>
        </a>
      </template>
    </div>
    <div class="scroll-arrow scroll-right" @click="scrollRight()">
      <icon-right />
    </div>
  </div>

</template>

<script>
import router from "@/router";
import { ref } from "vue";
export default {
  name: 'MarqueeCard',
  components:{

  },
  props:{
    list:{
      type:Array
    }
  },
  methods:{
    left: function(){
      this.scrollLeft()
    },
    right:function (){
      this.scrollRight()
    }
  },
  setup(){
    const scrollMarquee = ref();

    function scrollLeft(){
      scrollMarquee.value.scrollTo({
        left: scrollMarquee.value.scrollLeft - scrollMarquee.value.children[0].offsetWidth,
        behavior: 'smooth'
      })
    }

    function scrollRight(){
      scrollMarquee.value.scrollTo({
        left: scrollMarquee.value.scrollLeft + scrollMarquee.value.children[0].offsetWidth,
        behavior: 'smooth'
      })
    }

    return{
      scrollMarquee,
      scrollLeft,
      scrollRight
    }
  }


}
</script>

<style scoped>

  .marquee{
    display: flex;
    align-items: center;
    position: relative;
  }

  .marquee:before{
    position: absolute;
    left: 4.9rem;
    top: 0;
    width: 0;
    height: 100%;
    border-left: 0.5rem solid #fff;
    content: '';
    display: block;
    z-index: 101;
  }

  .marquee:after{
    position: absolute;
    right: 4.9rem;
    top: 0;
    width: 0;
    height: 100%;
    border-right: 0.5rem solid #fff;
    content: '';
    display: block;
    z-index: 101;
  }

  .scroll-marquee{
    width: calc(100% - 2px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }

  .scroll-item{
    position: relative;
    background-position: center;
    background-size: cover;
    border-left: 0.1rem solid #f0f0f0;
    padding: 0 1.6rem;
    box-sizing: border-box;
  }

  .scroll-item:first-child{
    border-left: none;
  }

  .scroll-item img{
    width: 100%;
  }

  .scroll-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    background-color: rgba(217,217,217,.3);
    opacity: .3;
    color: #000;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.3;
    user-select: none;
    padding-bottom: 0.5rem;
    font-weight: 900;
  }

  .marquee-title{
    margin: 2.4rem 0 1.5rem;
    line-height: 2.4rem;
    font-weight: 700;
    font-size: 2rem;
  }

  .marquee-subtitle{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    color: #8c8c8c;
  }


</style>
