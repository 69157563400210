<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <Menu :menu="menu" @changeNav="menuChangeNav">
    <template #wrapper-1>
      <div class="menu-nav" v-show="action_nav === 0">
        <div style="display: flex">
          <div style="width: 20rem">
            <div class="title">根据型号选择</div>
            <ul>
              <template v-for="(item, index) in menu_android.nav">
                <li>
                  <div class="text" @click="pageJump('collect',{brand:[item.brand_name]} )" v-on:mouseover="changeNav(index,'android')" :style="{'color':index === getNav('android')?'#999':''}">{{item.brand_name}}</div>
                  <div><icon-right size="1.5rem" /></div>
                </li>
              </template>
            </ul>
            <div style="cursor: pointer"></div>
          </div>
          <a-divider style="margin-left: 2rem" direction="vertical" />
          <div style="margin-left: 2rem;width: 100%">
            <template v-for="(item, index) in menu_android.nav">
              <div v-show="index === getNav('android')">
                <a-row class="grid-row" justify="space-between">
                  <template v-for="(item2, index2) in item.series_list">
                    <a-col :span="4">
                      <div><textList :title="item2.series_name" :list="item2.modelList" _jump_type="_textList" _jump_data="model_name" :brand="item.brand_name" :series="item2.series_name"></textList></div>
                    </a-col>
                  </template>
                  <a-col :span="4" v-if="item.series_list.length < 4" v-for="_ in 4 - item.series_list.length" />
                </a-row>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

  <template #wrapper-2>
    <div class="menu-nav" v-show="action_nav === 1">
      <div style="display: flex">
        <div style="width: 20rem">
          <div class="title">根据型号选择</div>
          <ul>
            <template v-for="(item, index) in menu_apple.nav">
              <li>
                <div class="text" @click="pageJump('collect',{brand:[item.brand_name]} )" v-on:mouseover="changeNav(index,'apple')" :style="{'color':index === getNav('apple')?'#999':''}">{{item.brand_name}}</div>
                <div><icon-right size="1.5rem" /></div>
              </li>
            </template>
          </ul>
          <div style="cursor: pointer"></div>
        </div>
        <a-divider style="margin-left: 2rem" direction="vertical" />
        <div style="margin-left: 2rem;width: 100%">
          <template v-for="(item, index) in menu_apple.nav">
            <div v-show="index === getNav('apple')">
              <a-row class="grid-row" justify="space-between">
                <template v-for="(item2, index2) in item.series_list">
                  <a-col :span="4">
                    <div><textList :title="item2.series_name" :list="item2.modelList" _jump_type="_textList" _jump_data="model_name" :brand="item.brand_name" :series="item2.series_name"></textList></div>
                  </a-col>
                </template>
                <a-col :span="4" v-if="item.series_list.length < 4" v-for="_ in 4 - item.series_list.length" />
              </a-row>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>

    <template #wrapper-3>
      <div class="menu-nav" v-show="action_nav === 2">
        <div style="display: flex">
          <div style="width: 20rem">
            <div class="title">选购联名系列</div>
            <ul>
              <template v-for="(item, index) in menu_co._nav">
                <li>
                  <div class="text" v-on:mouseover="changeNav(index,'co')" :style="{'color':index === getNav('co')?'#999':''}">{{item.title}}</div>
                  <div><icon-right size="1.5rem" /></div>
                </li>
              </template>
            </ul>
            <div style="cursor: pointer"></div>
          </div>
          <a-divider style="margin-left: 2rem" direction="vertical" />

          <div style="margin-left: 2rem;width: 100%">
            <template v-for="(item, index) in menu_co._nav">
              <div v-show="index === getNav('co')">
                <a-row class="grid-row" justify="space-between">
                  <IconList :title="item.name" :list="menu_co[item.key]"></IconList>
                </a-row>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

  </Menu>
  <router-view></router-view>
  <Footer></Footer>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.grid-row{
  width: 100%;
}

.grid-col-model div:not(:first-child){
  margin-top: 4rem;
}

.smooth-button{
  font-size: 1.6rem;
  background-color: #000!important;
  color: #fff!important;
  text-decoration: none;
  padding: 1rem 2.4rem;
  display: inline-block;
  border-radius: 10rem;
  line-height: 2rem;
  font-weight: 700;
  cursor: pointer;
}

.content-title{
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 120%;
}

.menu-nav{
  font-size: 1.5rem;
  padding-left: 20rem;
  padding-right: 20rem;
  background: #FFFFFF;
  padding-bottom: 5rem;
}
.menu-nav .title{
  font-size: 1.5rem;
  margin-bottom:  1.5rem;
  color: #999
}
.menu-nav ul{
  list-style: none;
  padding-left: 0;
  width: 100%;
}

.menu-nav li{
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: baseline;
}

.menu-nav li .text{
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex: 1;
}

</style>

<script setup>
import Menu from "@/components/Menu.vue";
import TextList from "@/components/TextList.vue";
import MenuNav from "@/components/MenuNav.vue";
import PostList from "@/components/PostList.vue";
import IconList from "@/components/IconList.vue";
import {getMenu} from "@/api/api";
import {defineComponent, ref} from "vue";
import router from "@/router";
import Footer from "@/components/Footer.vue";

const components = defineComponent({
  MenuNav,
  Menu,
  TextList,
  PostList,
  IconList,
  Footer
});
const action_nav = ref(-1)
const menuChangeNav = function (index){
  action_nav.value = index
}
const menu_android = ref({
  nav:[

  ]
});

const menu_apple = ref({
  nav:[

  ]
});

const menu_co = ref({
  art:[],
  role:[],
  entertainment:[],
  life:[],
  sport:[],
  other:[],
  _nav:[
    {
      title:"艺术",
      key:"art",
    },
    {
      title:"角色",
      key:"role",
    },
    {
      title:"娱乐",
      key:"entertainment",
    },
    {
      title:"生活",
      key:"life",
    },
    {
      title:"运动",
      key:"sport",
    },
    {
      title:"其他",
      key:"other",
    }]
})

let nav_now = ref({
});

(async()=>{
  getMenu().then(res=>{
    console.log("请求菜单",res)
    let android_list = res.data.android
    let apple_list = res.data.apple
    let co_list = res.data.co
    for (let i = 0; i < android_list.length; i++) {
      let item = android_list[i]
      menu_android.value.nav.push({
        brand_name:item.brand_name,
        brand_id:item.brand_id,
        series_list:item.seriesList
      })
    }
    console.log("android", menu_android)
    for (let i = 0; i < apple_list.length; i++) {
      let item = apple_list[i]
      menu_apple.value.nav.push({
        brand_name:item.brand_name,
        brand_id:item.brand_id,
        series_list:item.seriesList
      })
    }
    console.log("apple", menu_apple)

    for (let i = 0; i < co_list.length; i++) {
      let item = co_list[i]
      for (let j = 0; j < item.co_item.length; j++) {
        let item2 = item.co_item[j]
        menu_co.value[item.co_type].push(item2)
      }
    }
    console.log("co", menu_co)
  })
})();

const changeNav = function (index, menu_name){
  nav_now.value[menu_name] = index
}

const getNav = function (menu_name){
  if(nav_now.value.hasOwnProperty(menu_name)){
    return nav_now.value[menu_name]
  }else{
    return 0
  }
}

const jumpSku = function (sku_id, spu_id=""){
  router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
}

const jumpSpu = function (spu_id){
  router.push({path:"/product/",query:{spu:spu_id}})
}

const jumpCollect = function (attributes){
  let query = {}
  query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
  query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
  query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
  query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
  query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
  query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
  query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
  router.push({path:"/collect/",query:query})
}

const pageJump = function (jump_type, data){
  switch (jump_type) {
    case 'sku':
      jumpSku(data.sku_id, data.spu_id)
      break;
    case 'spu':
      jumpSpu(data.spu_id)
      break;
    case 'collect':
      jumpCollect(data)
      break;
    default:
      break;
  }
}

const menu = [
  {title:"安卓", id:1, jump_type:"", jump_data:{brand:['android']}},
  {title:"iPhone", id:2, jump_type:"", jump_data:{brand:['Apple']}},
  {title:"联名系列", id:3},
]


</script>