<script>
  import {ref} from "vue";

  export default {
    name:"PostList",
    props:{
      list:{
        type:Array
      },
      title:{
        type:String
      },
      btn:{
        type:Object
      }
    },
    setup(){
      const action_nav = ref(0)

      function changeNav(index){
        action_nav.value = index
      }
      return {action_nav, changeNav}
    }
  }
</script>

<template>
  <div class="post-list">
    <a class="title">{{title}}</a>
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <img :src="item.image" />
        <p style="text-align: center">{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .post-list{
    font-size: 1.6rem;
  }
  .post-list .title{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  .post-list ul{
    display: flex;
    list-style: none;
    padding-left: 0;
  }
  .post-list li{
    flex: 1 0 calc(100% / 8);
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  .post-list img{
    height: 25rem;
    width: 100%;
    object-fit: cover;
  }
</style>