import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import SeriesView from "@/views/SeriesView.vue";
import ProductView from "@/views/ProductView.vue";
import ContactUsview from "@/views/ContactUsView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import MediaCoView from "@/views/MediaCoView.vue";
import RecruitView from "@/views/RecruitView.vue";
import SecurityCodeView from "@/views/SecurityCodeView.vue";
import StoreInfoView from "@/views/StoreInfoView.vue";



const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/collect',
    name: 'collect',
    component: SeriesView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/product',
    name: 'product',
    component: ProductView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUsview,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUsView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/mediaCo',
    name: 'mediaCo',
    component: MediaCoView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: RecruitView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/securityCode',
    name: 'securityCode',
    component: SecurityCodeView,
    meta: {
      title: 'NMAX'
    }
  },
  {
    path: '/storeInfo',
    name: 'storeInfo',
    component: StoreInfoView,
    meta: {
      title: 'NMAX'
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
